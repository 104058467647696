@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
  font: var(--font-poppins);
}
:root {
  /* fonts */
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;

  /* Colors */
  --color-white: #fff;
  --color-mediumseagreen: #51c569;
  --color-blue: #516BC5;
  --color-whitesmoke: #f7f7f9;
  --color-darkslategray: #314559;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-7xs: 6px;
  --gap-xl: 20px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-xs: 12px;
  --padding-5xl: 24px;

  /* Border radiuses */
  --br-xs: 12px;
}
