// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

.active{
  font-weight: bold;
  color: #0C94EA !important;
  text-wrap: nowrap;
}

.side-menu-icon{
  background: black;
  display: inline-block;
  padding: 0.1rem;
  border-radius: 20px;
  text-align: center;
  width: 35px;
  height: 35px;
  margin-right: 15px;
}


.active{
  .side-menu-icon{
    background-color: $blue;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}