//common
.rounded-border {
  border-radius: 20px;
}

.bg-col {
  background-color: #0C94EA
  ;
}

.save-btn {
  background-color: #0C94EA !important;
  border-radius: 20px;
}

.save-btn:hover {
  background-color: #0C94EA !important;
  opacity: 0.9;
}

.text-color {
  color: #0C94EA;
}

.cancel-btn {
  background-color: transparent;
  outline: none;
  border: 1px solid #0C94EA;
  color: #0C94EA;
  border-radius: 20px;
}

.cancel-btn:hover {
  background-color: #0C94EA !important;
  color: white;
}

// dashboard styles
.checkWrap {
  background-color: #f7f7f9;
  display: flex;
  justify-content: space-between;
  // padding-top: 10px;
}

.wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  p {
    font-size: 18px;
    font-weight: 500;
  }
}

.checkboxWrap {
  color: #0C94EA;
}

.checkboxWrap .form-check {
  min-height: 42px !important;
}

.UserInfo {
  display: flex;
  gap: 10px;
}

.user-img {
  border: 1px solid;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
  }
}

.UserWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icon {
  width: 15px;
  height: 15px;
}

.user-name {
  display: flex;
  flex-direction: column;

  color: #37353b;
}

.user-name > :first-child {
  font-size: 12px;
  color: gray;
  /* Add your styles here */
}
.title {
  font-size: 18px;
  font-weight: 500;
}

.location {
  display: flex;
  width: 100%;
  gap: 10px;
}

.upc {
  color: gray;
}

.view {
  color: #0C94EA;
  cursor: pointer;
}

.award-icon {
  background-color: #0C94EA;
  width: 35px;
  height: 33px;
  text-align: center;
  border-radius: 20px;
  padding: 5px;
  color: #f7f7f9;
}

.prop-icon {
  background-color: #3ee5bd !important;
}

.img-icon {
  width: 20px;
  height: 20px;
}

.custom-invalid-feedback {
  position: absolute;
  bottom: -20px;
}

.cutome {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 42px;
  top: 37px;
  cursor: pointer;
}

.sug-text {
  position: absolute;
  right: 10px;
  top: 68px;
  font-size: 10px;
}

.breadcrumb-item > a {
  color: #999999 !important;
}

.pending {
  color: #f89812;
}
.verfication {
  color: #c43ee5;
}

.completed {
  color: #12f852;
}

.add-btn {
  color: #0C94EA;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.prop-img {
  width: 100vw;
  height: 39vh;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
}

.Alcohol-radios {
  background-color: #fafafa !important;
  border: 1px solid white !important;
}

.Alcohol-radios > label {
  color: black;
  border-color: #eaebec !important;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 112px;
  font-size: medium;
  font-weight: 500;
}

.btn-tgl {
  background-color: #fafafa !important;
}

.Alcohol-active {
  background-color: #0C94EA !important;
  column-width: auto;
  color: white;
}
.text-leon {
  width: 274%;
}
.card-card {
  width: 39rem;
  height: 18rem;
  display: flex;
  justify-content: space-evenly;
  box-shadow: none;
}
.comment {
  padding: 15px;
  font-size: 12px;
  background-color: #37353b0a;
  font-family: "Poppins";
  border-radius: 10px;
}
.urgent {
  background-color: #e55c3e1f;
  width: 132px;
  border: 1px solid #e55c3e3d;
  color:  #E55C3E;
  ;
}
.snag-task {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
}
.icon {
  color: #0C94EA;
}
.text-home {
  color: #0C94EA;
  background-color: #0C94EA;
  width: 130px;
  border: 1px solid #0C94EA;
}
.late {
  color: #F89812;
  ;
  background-color: #f898121f;

  width: 136px;
  border:  1px solid #F898123D;
}
.text-verification{
    color: #C43EE5;
    ;
}
.border-line{
    width: 1487px
    ;
    margin-left: -1%;
}
.time{
    margin-bottom: 6px;
}
.custom-label{
  margin-bottom: 9px;
}
.add_image_icon{
  position: relative;
  height: 60px !important;
  width: 60px !important;
  input{
    position: absolute;
    top: 0;
    opacity:0;
    height: 76px !important;
    width: 76px !important;
    cursor :pointer
  }
}
.upload-button{
  display: flex;
  align-items: flex-end;
  justify-content: center;

  button{
    font-size: 12px !important;
  }
}
.custom_input{
  width: 150px;

}
.form-switch .form-check-input {
  width: 2.5rem !important;
}

.version-info {
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 0.75rem;
  color: #666;
  line-height: 1.2;
  z-index: 1000;
  opacity: 0.8;
  
  // Add hover effect for better visibility
  &:hover {
    opacity: 1;
  }
}